<template>
	<div class="traitements">
		<div class="traitements__list qwer">
			<h2 class="site-title">Traitements</h2>
			<h3
				class="traitements__item"
				v-for="(traitement, index) in traitements"
				@click="chengeActiveTraitement(index)"
				:class="currentTraitementIndex == index ? 'active' : ''"
				:key="traitement.id"
			>
				<div>
					<span class="traitements__item-num"
						>{{ getCurrentIndex(index) }}.</span
					>
					<span
						class="traitements__item-text"
						v-html="traitement.left_title"
					></span>
				</div>
				<div
					class="traitements__content"
					v-if="isWindowWidthMobile && currentTraitementIndex == index"
					:key="currentTraitementIndex"
				>
					<transition
						:name="
							prevSlideIndex > currentTraitementIndex
								? 'animate-content-top'
								: 'animate-content-bottom'
						"
					>
						<div>
							<div class="site-text" v-html="currentTraitement.content"></div>
							<div class="traitements__btn-container">
								<a :href="currentTraitement.url" class="btn btn--dark-blue">{{
									currentTraitement.url_text
								}}</a>
							</div>
						</div>
					</transition>
				</div>
			</h3>
		</div>
		<div class="traitements__content">
			<span class="traitements__current"
				>{{ getCurrentIndex(currentTraitementIndex) }}.</span
			>
			<transition
				:name="
					prevSlideIndex > currentTraitementIndex
						? 'animate-content-top'
						: 'animate-content-bottom'
				"
			>
				<div v-if="currentTraitement" :key="currentTraitementIndex">
					<span
						class="site-title"
						v-html="currentTraitement.right_title"
					></span>
					<div class="site-text" v-html="currentTraitement.content"></div>
					<div class="traitements__btn-container">
						<a :href="currentTraitement.url" class="btn btn--dark-blue">{{
							currentTraitement.url_text
						}}</a>
					</div>
				</div>
			</transition>
		</div>
		<div class="traitements__img">
			<transition
				:name="
					prevSlideIndex > currentTraitementIndex
						? 'animate-img-right'
						: 'animate-img-left'
				"
			>
				<img
					:src="currentTraitement.thumb"
					:key="currentTraitementIndex"
					v-if="currentTraitement"
					alt=""
				/>
			</transition>
		</div>
	</div>
</template>

<script>
const axios = require("axios").default;

export default {
	name: "Traitements",
	props: {},
	data() {
		return {
			traitements: [],
			currentTraitement: null,
			currentTraitementIndex: 0,
			prevSlideIndex: 0,
		};
	},
	created() {
		this.getTraitements();
	},
	methods: {
		getTraitements() {
			const currentTraitementIndex = parseInt(
				localStorage.getItem("currentTraitementIndex")
			);

			if (currentTraitementIndex) {
				this.currentTraitementIndex = currentTraitementIndex;
			}

			axios.get("/wp-json/dr/v2/traitements").then((res) => {
				this.traitements = res.data;
				this.currentTraitement = res.data[this.currentTraitementIndex];
			});
		},
		getCurrentIndex(num) {
			return (++num + "").length > 1 ? num : "0" + num;
		},
		chengeActiveTraitement(index) {
			if (index === this.currentTraitementIndex) {
				this.prevSlideIndex = this.currentTraitementIndex;
				this.currentTraitementIndex = 0;
				this.currentTraitement = this.traitements[0];
				localStorage.setItem("currentTraitementIndex", 0);
			} else {
				this.prevSlideIndex = this.currentTraitementIndex;
				this.currentTraitementIndex = index;
				this.currentTraitement = this.traitements[index];
				localStorage.setItem("currentTraitementIndex", index);
			}
		},
	},
	computed: {
		isWindowWidthMobile() {
			return window.innerWidth < 768;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.traitements {
	display: flex;
	overflow: hidden;
	$traitements: &;

	@media (max-width: 991px) {
		flex-wrap: wrap;
	}

	@media (max-width: 767px) {
		> .traitements__content {
			display: none;
		}
	}

	&__list {
		flex: 0 0 35%;
		background-color: #f1f2f4;
		padding: 80px 53px;
		text-align: left;

		.site-title {
			margin-bottom: 68px;

			@media (max-width: 1700px) {
				margin-bottom: 35px;
				font-size: 40px;
			}
		}

		@media (max-width: 1900px) {
			padding: 40px 53px;
		}

		@media (max-width: 1500px) {
			padding: 30px 25px;
		}

		@media (max-width: 1400px) {
			.site-title {
				margin-bottom: 30px;
			}
		}

		@media (max-width: 991px) {
			flex: 0 0 100%;
			padding: 30px 25px;
		}
	}

	&__item-num {
		color: #6c6b6b;
		margin-right: 15px;
		transition: all 0.3s;
		width: 30px;
	}

	&__item {
		font: 24px/29px "Gilroy";
		color: #062355;
		font-weight: 800;
		display: flex;
		flex-direction: column;
		transition: all 0.3s;

		@media (max-width: 1700px) {
			font-size: 20px;
		}

		&:not(:last-child) {
			margin-bottom: 24px;

			@media (max-width: 1700px) {
				margin-bottom: 14px;
			}
		}

		> div:first-child {
			display: flex;
		}

		&.active {
			color: #29bcef;

			#{$traitements}__item-num {
				color: #29bcef;
			}
		}

		&:hover {
			cursor: pointer;
			color: #29bcef;

			#{$traitements}__item-num {
				color: #29bcef;
			}
		}
	}

	&__content {
		position: relative;
		flex: 0 0 40%;
		padding: 60px;
		text-align: left;

		.site-title {
			@media (max-width: 1500px) {
				font-size: 40px;
				margin-bottom: 15px;
			}
		}

		@media (max-width: 1900px) {
			padding: 20px 50px;
		}

		@media (max-width: 1700px) {
			padding: 20px 30px;
		}

		@media (max-width: 1024px) {
			flex-grow: 1;
		}

		// @media (max-width: 991px) {
		// 	flex: 0 0 70%;
		// 	width: 70%;
		// }

		@media (max-width: 767px) {
			// flex: 0 0 100%;
			// width: 100%;
			padding: 30px 0;
		}
	}

	&__btn-container {
		text-align: right;
	}

	&__current {
		display: block;
		font: 72px/78px "Gilroy";
		color: #e7e7e7;
		font-weight: 800;
		margin-bottom: 48px;

		@media (max-width: 1700px) {
			margin-bottom: 18px;
			font-size: 62px;
			line-height: 62px;
		}

		@media (max-width: 550px) {
			margin-bottom: 25px;
		}
	}

	.site-title {
		@media (max-width: 768px) {
			font-size: 32px;
		}
	}

	.site-text {
		margin-bottom: 64px;
		text-align: justify;
		max-height: 310px;
		overflow-y: auto;
		padding-right: 10px;

		@media (max-width: 1700px) {
			margin-bottom: 30px;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 4px;
			background-color: #bde9ff;
		}

		&::-webkit-scrollbar {
			width: 4px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: #062355;
		}

		@media (max-width: 550px) {
			margin-bottom: 30px;
		}
	}

	.btn {
		margin: 0 0 0 auto;
	}

	&__img {
		position: relative;
		overflow: hidden;
		flex: 0 0 30%;

		img {
			max-width: 100%;
			width: 100%;
			height: auto;

			@media (max-width: 1300px) {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		@media (max-width: 1024px) {
			display: none;
		}
	}

	&--active {
		#{$traitements}__list {
			padding: 40px 53px;

			.site-title {
				margin-bottom: 40px;
			}

			@media (max-width: 1630px) {
				padding: 30px 25px;
			}
		}

		#{$traitements}__item {
			@media (max-width: 1830px) {
				font-size: 20px;

				&:not(:last-child) {
					margin-bottom: 14px;
				}
			}
		}

		.traitements__img {
			@media (max-width: 1550px) {
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

p {
	margin-bottom: 12px;
}

.animate-img-left {
	&-enter {
		transform: translateX(100%);
		opacity: 0.2;
	}
	&-enter-active {
		position: relative;
		z-index: 1;
		transition: all 0.7s;
	}
	&-enter-to {
		transform: translateX(0);
		opacity: 1;
	}

	&-leave-active {
		position: absolute;
		transition: all 0.6s;
		opacity: 1;
	}
	&-leave-to {
		opacity: 0;
	}
}

.animate-img-right {
	&-enter-active {
		opacity: 0;
		transition: all 1s;
	}
	&-enter-to {
		opacity: 1;
	}

	&-leave-active {
		position: absolute;
		transition: all 1s;
		opacity: 1;
		transform: translateX(0);
	}
	&-leave-to {
		opacity: 0;
		transform: translateX(100%);
	}
}

.animate-content-top {
	&-enter {
		opacity: 0;
	}
	&-enter-active {
		transition: all 1s;
	}
	&-enter-to {
		opacity: 1;
	}

	&-leave-active {
		transition: all 1s;
		position: absolute;
		left: 60px;
		right: 60px;
		opacity: 1;
	}
	&-leave-to {
		opacity: 0;
		transform: translateY(50px);
	}
}

.animate-content-bottom {
	&-enter {
		transform: translateY(50px);
		opacity: 0.2;
	}
	&-enter-active {
		transform: translateY(50px);
		transition: all 0.7s;
	}
	&-enter-to {
		transform: translateY(0);
		opacity: 1;
	}

	&-leave-active {
		transition: all 0.3s;
		opacity: 1;
		height: 0;
	}
	&-leave-to {
		opacity: 0;
	}
}
</style>
