var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"traitements"},[_c('div',{staticClass:"traitements__list qwer"},[_c('h2',{staticClass:"site-title"},[_vm._v("Traitements")]),_vm._l((_vm.traitements),function(traitement,index){return _c('h3',{key:traitement.id,staticClass:"traitements__item",class:_vm.currentTraitementIndex == index ? 'active' : '',on:{"click":function($event){return _vm.chengeActiveTraitement(index)}}},[_c('div',[_c('span',{staticClass:"traitements__item-num"},[_vm._v(_vm._s(_vm.getCurrentIndex(index))+".")]),_c('span',{staticClass:"traitements__item-text",domProps:{"innerHTML":_vm._s(traitement.left_title)}})]),(_vm.isWindowWidthMobile && _vm.currentTraitementIndex == index)?_c('div',{key:_vm.currentTraitementIndex,staticClass:"traitements__content"},[_c('transition',{attrs:{"name":_vm.prevSlideIndex > _vm.currentTraitementIndex
							? 'animate-content-top'
							: 'animate-content-bottom'}},[_c('div',[_c('div',{staticClass:"site-text",domProps:{"innerHTML":_vm._s(_vm.currentTraitement.content)}}),_c('div',{staticClass:"traitements__btn-container"},[_c('a',{staticClass:"btn btn--dark-blue",attrs:{"href":_vm.currentTraitement.url}},[_vm._v(_vm._s(_vm.currentTraitement.url_text))])])])])],1):_vm._e()])})],2),_c('div',{staticClass:"traitements__content"},[_c('span',{staticClass:"traitements__current"},[_vm._v(_vm._s(_vm.getCurrentIndex(_vm.currentTraitementIndex))+".")]),_c('transition',{attrs:{"name":_vm.prevSlideIndex > _vm.currentTraitementIndex
					? 'animate-content-top'
					: 'animate-content-bottom'}},[(_vm.currentTraitement)?_c('div',{key:_vm.currentTraitementIndex},[_c('span',{staticClass:"site-title",domProps:{"innerHTML":_vm._s(_vm.currentTraitement.right_title)}}),_c('div',{staticClass:"site-text",domProps:{"innerHTML":_vm._s(_vm.currentTraitement.content)}}),_c('div',{staticClass:"traitements__btn-container"},[_c('a',{staticClass:"btn btn--dark-blue",attrs:{"href":_vm.currentTraitement.url}},[_vm._v(_vm._s(_vm.currentTraitement.url_text))])])]):_vm._e()])],1),_c('div',{staticClass:"traitements__img"},[_c('transition',{attrs:{"name":_vm.prevSlideIndex > _vm.currentTraitementIndex
					? 'animate-img-right'
					: 'animate-img-left'}},[(_vm.currentTraitement)?_c('img',{key:_vm.currentTraitementIndex,attrs:{"src":_vm.currentTraitement.thumb,"alt":""}}):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }